import React, { useEffect, useState } from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import ProductForm from "../forms/ProductForm";
import Button from "@material-ui/core/Button";
import api from "../../utils/api";
import { Card, CardContent, CircularProgress, makeStyles, SwipeableDrawer, TextField, Typography } from "@material-ui/core";
import Variants from "./Variant";
import { productDefaults } from "../../utils/defaults";
import { Autosave } from "react-autosave";
import { useSavingState } from "../../context/saving";
import Fs1Input from "../layout/FsInput";
import { isVid } from "../forms/VariantForm";
import Gallery, { Videos } from "../../components/layout/Gallery";
import { useAlert } from "../../context/alert";

export const useDrawerStyles = makeStyles({
  root: {
    padding: 24,
  },
  cont: {
    width: "calc(50vw + 250px)",
    maxWidth: "90vw",
    height: "100%",
  },
});

const Product = ({}) => {
  const drawerStyles = useDrawerStyles();
  const match = useRouteMatch();
  const history = useHistory();

  const [product, setProduct] = useState(null);
  const [variant, setVariant] = useState(null);
  const [noteDrawerOpen, setNoteDrawerOpen] = useState(false);
  const [creatingVariant, setCreatingVariant] = useState(false);
  const { saving, saved, error } = useSavingState();
  const { showAlert } = useAlert();
  const [showMoreConfigurations, setShowMoreConfigurations] = useState(false);
  const [creatingNewProduct, setCreatingNewProduct] = useState(false);

  const createNewProduct = async () => {
    if (!product.show) return;
    setCreatingNewProduct(true);
    let lastAddedShowData = await api.get(`/shows/${product.show.id}/products?limit=1&sort=-updatedAt`);
    lastAddedShowData = lastAddedShowData.data[0];
    const data = {
      show: product.show.id,
      brand: lastAddedShowData?.brand || undefined,
      vendor: lastAddedShowData?.vendor || undefined,
      season: product.show.defaultProductSeason,
    };
    const res = await api.post(`/products`, data);
    history.push(`/products/${res.data._id}`);
    setCreatingNewProduct(false);
  };

  const getProductProperty = (name) => {
    return product[name] ?? productDefaults[name];
  };

  const change = (name, value, ...rest) => {
    let restValues = {};
    if (rest?.length && rest.length % 2 === 0) {
      restValues = {
        [rest[0]]: rest[1],
      };
    }
    saving();
    setProduct({
      ...product,
      [name]: value,
      ...restValues
    });
  };

  const handleNewImage = (e) => {
    const tempImages = [...images, ...e];
    updateProduct({ images: tempImages }).then(getProduct);
  };

  const saveOriginals = (e) => {
    const tempImages = [...getProductProperty("originalImages"), ...e];
    updateProduct({ originalImages: tempImages }).then(getProduct);
  };

  const onAssignExistingImages = (arr) => {
    handleNewImage(arr);
    const originalImages = arr.map((e) => product.show.unassignedImagesBinOriginals[product.show.unassignedImagesBin.indexOf(e)]);
    saveOriginals(originalImages);
    api.put("/shows/" + product.show._id, {
      unassignedImagesBin: product.show.unassignedImagesBin.filter((e) => !arr.includes(e)),
      unassignedImagesBinOriginals: product.show.unassignedImagesBinOriginals.filter((e) => !originalImages.includes(e)),
    });
  };

  const deleteImage = (i) => {
    if (window.confirm("Are you sure want to delete this image?")) {
      const tempImages = [...images];
      tempImages.splice(i, 1);
      change("images", tempImages);
    }
  };

  const getProduct = () => api.get(`/products/${match.params.product_id}?populate=variants`).then((res) => setProduct(res.data));

  const updateProduct = (data) => {
    return api
      .put(`/products/${match.params.product_id}`, {
        show: data.show?._id,
        vendor: data.vendor,
        brand: data.brand,
        productId: data.productId,
        group: data.group,
        season: data.season,
        store: data.store,
        images: data.images,
        originalImages: data.originalImages,
        scale: data.scale,
        notes: data.notes,
        description: data.description,
      })
      .then(({ data }) => {
        const { warning = false, messages = [] } = data;
        if (warning) {
          showAlert({ message: messages.join("\n") });
        }
      })
      .then(saved)
      .catch(error);
  };

  const createVariant = async (data) => {
    setCreatingVariant(true);
    data = data || {
      product: match.params.product_id,
      color: "Select Color",
      sizes: [
        {
          size: [],
        },
      ],
    };
    const res = await api.post("/variants", data);
    setVariant(res.data);
    setCreatingVariant(false);
    return res;
  };

  useEffect(getProduct, [match.params.product_id]);

  useEffect(() => {
    if (variant) {
      //keep in sync
      // setVariant(product.variants.find((e) => e._id === variant._id));
    }
  }, [product]);

  if (!product)
    return (
      <div className="center">
        <CircularProgress />
      </div>
    );

  const images = getProductProperty("images");
  const notes = getProductProperty("notes");
  const variantImagesArray = (() => {
    const newArr = [];
    product.variants.map((d) => {
      newArr.push(...d.images);
    });
    return newArr;
  })();

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 10 }}>
        <Button onClick={() => history.goBack()}>&lt; Back</Button>
        <Button onClick={() => setShowMoreConfigurations(!showMoreConfigurations)}>More Configurations</Button>
      </div>
      <ProductForm {...{ getProductProperty, change, handleNewImage, showMoreConfigurations, setShowMoreConfigurations }} />
      <div>
        <Variants
          {...{
            createVariant,
            getProduct,
            variant,
            setVariant,
            product,
          }}
        />{" "}
        <br />
        <br />
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button style={{ width: "auto", height: 30 }} variant="contained" size="small" color="primary" aria-label="add" disabled={creatingVariant} onClick={() => createVariant()}>
            + Add Color & Sizes
          </Button>
          <div style={{ width: "50px" }}>
            <Fs1Input onChange={handleNewImage} saveOriginals={saveOriginals} optimize onDoneSelecting={onAssignExistingImages} existingImages={product.show.unassignedImagesBin || []} />
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Button onClick={() => setNoteDrawerOpen(true)}>Add notes and images</Button>
      <Typography variant="subtitle1" gutterBottom onClick={() => setNoteDrawerOpen(true)}>
        {notes.slice(0, 20)}
        {notes.length > 19 ? "... " : ""}
        {notes.length > 0 ? " + " : ""}
        {images.length ? `${images.length} image` : ""}
        {images.length > 1 ? "s" : ""}
      </Typography>
      <br />
      <br /> <br />
      <div style={{ position: "sticky", bottom: 5, marginLeft: "calc(50% - 60px)" }}>
        <Button variant="contained" size="small" color="primary" aria-label="add" disabled={creatingNewProduct} onClick={createNewProduct}>
          + New Item
        </Button>
      </div>
      <SwipeableDrawer anchor={"right"} open={noteDrawerOpen} onClose={() => setNoteDrawerOpen(false)}>
        <div className={drawerStyles.cont}>
          <Button onClick={() => setNoteDrawerOpen()}>&lt; Back</Button>
          <br />
          <br />
          <Card>
            <CardContent>
              <TextField
                size="small"
                color="primary"
                label="Notes"
                fullWidth
                autoFocus
                multiline
                onFocus={function (e) {
                  //to avoid autofocus bug of cursor going to begining of input
                  var val = e.target.value;
                  e.target.value = "";
                  e.target.value = val;
                }}
                rows="6"
                onChange={(e) => change("notes", e.target.value)}
                value={getProductProperty("notes")}
                variant="outlined"
                placeholder="Notes"
              />
            </CardContent>
          </Card>
          <br />
          <br />
          <Fs1Input onChange={handleNewImage} saveOriginals={saveOriginals} optimize onDoneSelecting={onAssignExistingImages} existingImages={product.show.unassignedImagesBin || []} />
          <br />
          <br />

          <Gallery images={images.filter((e) => !isVid(e))} deleteImage={deleteImage} />
          <br />
          <br />
          <br />
          <br />
          <Typography variant="h5">Additional Images</Typography>
          <Gallery images={variantImagesArray.filter((e) => !isVid(e))} />
          <br />
          <br />
          <br />
          <br />
          <Typography variant="h5">Videos</Typography>
          <Videos videos={images.filter(isVid)} deleteVideo={deleteImage} />
          <Videos videos={variantImagesArray.filter(isVid)} />
        </div>
      </SwipeableDrawer>
      <Autosave data={product} onSave={updateProduct} />
    </div>
  );
};

export default Product;
