import React, { useRef, useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { matchSorter } from "match-sorter";
import { Divider, Grid } from "@material-ui/core";
import api from "../../utils/api";

export const filterOptions = (options, { inputValue }) => matchSorter(options, inputValue);

const Product = ({ change, getProductProperty, showMoreConfigurations }) => {
  const firstRender = useRef(true);
  const brandRef = useRef();
  const description = getProductProperty("description");
  const brand = getProductProperty("brand");
  const vendor = getProductProperty("vendor");
  const group = getProductProperty("group");
  const store = getProductProperty("store");
  const [brandsOptions, setBrandOptions] = useState([]);
  const [storeOptions, setStoreOptions] = useState([]);
  const [subSubGroups, setSubSubGroups] = useState([]);
  const [originalSubSubGroups, setOriginalSubSubGroups] = useState([]);
  const [lilyGroups, setLilyGroups] = useState([]);
  const [sockGroups, setSockGroups] = useState([]);
  const [autoCompleteValues, setAutoCompleteValues] = useState({
    brand,
    vendor,
    description,
    group,
    store
  });

  const isSockShow = getProductProperty("show").store === "SS - Sock Shop";

  const onAutoCompleteValuesChange = (key, value) => {
    setAutoCompleteValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  useEffect(() => {
    api.get("/autocompleteOptions/subSubGroups").then((e) => {
      if (isSockShow) {
        setSubSubGroups(e.data.map((e) => e.name));
      } else {
        setOriginalSubSubGroups(e.data.map((e) => e));
        setSubSubGroups(e.data.filter((subSubGroup) => subSubGroup?.lilyGroups?.some((lilyGroup) => lilyGroup.name === autoCompleteValues.group)).map((subSubGroup) => subSubGroup.name));
      }
    });
    api.get("/autocompleteOptions/lilyGroups").then((e) => setLilyGroups(e.data.map((e) => e)));
    api.get("/autocompleteOptions/sockGroups").then((e) => setSockGroups(e.data.map((e) => e)));
    api.get("/autocompleteOptions/brands").then((e) => setBrandOptions(e.data.map((e) => e.name)));
    api.get("/autocompleteOptions/stores").then((e) => setStoreOptions(e.data));
  }, []);

  useEffect(() => {
    if (!firstRender.current) {
      if (showMoreConfigurations) {
        brandRef.current.focus();
      }
    }
    firstRender.current = false;
  }, [brandsOptions, showMoreConfigurations]);

  return (
    <form>
      {showMoreConfigurations && (
        <>
          <TextField label="Season" fullWidth value={getProductProperty("season")} onChange={(e) => change("season", e.target.value)} variant="outlined" placeholder="season" size="small" />
          <br />
          <br />
          <TextField label="Scale" fullWidth value={getProductProperty("scale")} onChange={(e) => change("scale", e.target.value)} variant="outlined" placeholder="Scale" size="small" />
          <br />
          <br />
          <Divider />
          <br />
        </>
      )}
      <Autocomplete
        size="small"
        multiple={true}
        options={storeOptions.filter(e=>e.name.toLowerCase().includes(isSockShow?'sock':'lily'))}
        getOptionLabel={(option) => (typeof option === "object" ? option.name || "" : option)}
        loading={!storeOptions.length}
        value={storeOptions.filter(e=>store?.includes(e.name.slice(0,e.name.indexOf(' - '))))}
        onChange={(e, v) => {
          v&&
          change("store", v?.map(e=>e.name.slice(0,e.name.indexOf(' - ')) || "").join(',')||'');
        }}
        inputValue={autoCompleteValues.store}
        onInputChange={(e, newVal) => {
          
          onAutoCompleteValuesChange("store", newVal);
        }}
        renderInput={(params) => <TextField {...params} label="Store" variant="outlined" size="small" />}
      />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField label="Product ID" fullWidth variant="outlined" value={getProductProperty("productId")} onChange={(e) => change("productId", e.target.value)} size="small" />
        </Grid>
        {/* <Grid item xs={2}> */}
        {/* <Fs1Input value={getProductProperty("images")[getProductProperty("images").length - 1]} onChange={handleNewImage} />
        </Grid> */}
      </Grid>
      <br />
      <Autocomplete
        size="small"
        freeSolo
        filterOptions={(options) => {
          const inputValueLower = autoCompleteValues.group.toLowerCase();
          return options.filter((option) => {
            if (typeof option === "object" && option.name) {
              return option.name.toLowerCase().includes(inputValueLower);
            }
            return option.toLowerCase().includes(inputValueLower);
          });
        }}
        options={isSockShow ? sockGroups : lilyGroups}
        getOptionLabel={(option) => (typeof option === "object" ? option.name || "" : option)}
        loading={isSockShow ? !sockGroups.length : !lilyGroups.length}
        value={{ name: autoCompleteValues.group }}
        onChange={(e, v) => {
          change("group", v?.name || "");
          if (!isSockShow && v) {
            setSubSubGroups(originalSubSubGroups.filter((subSubGroup) => subSubGroup?.lilyGroups?.some((lilyGroup) => lilyGroup.id === v.id)).map((subSubGroup) => subSubGroup.name));
          }
        }}
        inputValue={autoCompleteValues.group}
        onInputChange={(e, newVal) => {
          const groups = isSockShow ? sockGroups : lilyGroups;
          if (!groups.find((group) => String(group.name).toLowerCase().includes(newVal.toLowerCase()))) {
            return;
          }
          onAutoCompleteValuesChange("group", newVal);
        }}
        renderInput={(params) => <TextField {...params} label="Group" onBlur={(e) => change("group", e.target.value)} variant="outlined" size="small" />}
      />
      <br />

      <Autocomplete
        size="small"
        filterOptions={filterOptions}
        options={subSubGroups}
        getOptionLabel={(option) => option}
        loading={!subSubGroups.length}
        value={description}
        noOptionsText=""
        onChange={(e, v) => {
          change("description", v || "");
        }}
        inputValue={autoCompleteValues.description}
        onInputChange={(e, newVal) => {
          // if (e?.type === "change") {
          //   if (subSubGroups.findIndex((subSubGroup) => newVal.startsWith(subSubGroup)) === -1) {
          //     return;
          //   }
          // }
          if (!(subSubGroups.find((subSubGroup) => String(subSubGroup).toLowerCase().startsWith(newVal.toLowerCase()))
          || subSubGroups.find((subSubGroup) => String(newVal).toLowerCase().includes(subSubGroup.toLowerCase())))) {
            return;
          }
          onAutoCompleteValuesChange("description", newVal);
        }}
        renderInput={(params) => (
          <TextField {...params} label="SSG/Description" onBlur={(e) => change("description", e.target.value)} variant="outlined" size="small" placeholder="Vest for toddlers" />
        )}
      />
      <br />
      <Autocomplete
        size="small"
        filterOptions={filterOptions}
        options={brandsOptions || []}
        loading={!brandsOptions.length}
        value={getProductProperty("brand")}
        inputValue={autoCompleteValues.brand}
        onInputChange={(e, val) => {
          if (!brandsOptions.find((brand) => String(brand).toLowerCase().includes(val.toLowerCase()))) {
            return;
          }
          onAutoCompleteValuesChange("brand", val);
        }}
        onChange={(e, v) => change("brand", v || "")}
        renderInput={(params) => <TextField inputRef={brandRef} {...params} label="Brand" onBlur={(e) => change("brand", e.target.value)} variant="outlined" size="small" />}
      />
    </form>
  );
};

export default Product;
